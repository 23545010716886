'use client';

import { SnackbarProvider } from 'notistack';
import { greycliffRegular } from 'ui';
import React from 'react';

const AppSnackbarProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      style={{ fontFamily: greycliffRegular.style.fontFamily }}
    >
      {children}
    </SnackbarProvider>
  );
};

export default AppSnackbarProvider;
