import { Prisma, PrismaClient } from '@prisma/client';
import { readReplicas } from '@prisma/extension-read-replicas';

const forUser = (userId: string) => {
  return Prisma.defineExtension((prisma) =>
    prisma.$extends({
      query: {
        $allModels: {
          async $allOperations({ args, query }) {
            const [, result] = await prisma.$transaction([
              prisma.$executeRaw`SELECT set_config('app.current_user_id', ${userId}, TRUE)`,
              query(args),
            ]);
            return result;
          },
        },
      },
    }),
  );
};

const createExtendedClient = (): PrismaClient => {
  const client = new PrismaClient();

  if (process.env.DATABASE_REPLICA_1_URL) {
    return client.$extends(
      readReplicas({
        url: process.env.DATABASE_REPLICA_1_URL,
      }),
    ) as unknown as PrismaClient;
  }

  return client;
};

const globalForPrisma = globalThis as unknown as {
  prisma: PrismaClient | undefined;
};

const prisma = globalForPrisma.prisma ?? createExtendedClient();

const getPrismaUserClient = (userId: string) => {
  return prisma.$extends(forUser(userId));
};

export * from '@prisma/client';
export * from './middleware';

export { prisma, getPrismaUserClient };

if (process.env.NODE_ENV !== 'production') globalForPrisma.prisma = prisma;
