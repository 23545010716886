'use client';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useEffect, useState } from 'react';
import useInterval from 'ui/hooks/useInterval';
import { trpc } from 'trpc/client';

const UpdateCheck = () => {
  const [needReload, setNeedReload] = useState(false);
  const { data, refetch } = trpc.build.useQuery(undefined, {
    enabled: false,
  });

  useInterval(
    async () => {
      await refetch();
    },
    { interval: 30000 },
  );

  useEffect(() => {
    if (data) {
      const { buildId } = data;
      if (buildId && process.env.BUILD_ID && buildId !== process.env.BUILD_ID) {
        setNeedReload(true);
      }
    }
  }, [data]);

  const handleReload = (event: object, reason?: string) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return;
    }

    window.location.reload();
  };

  return (
    <Dialog open={needReload} onClose={handleReload}>
      <DialogTitle id="alert-dialog-title">New App Version Available</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          A new version of the app is available. Please reload the page to get the latest version.
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: 'center',
          padding: '1em',
          borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        }}
      >
        <Button variant="contained" onClick={handleReload} data-testid="reloadNowBtn">
          Reload Now
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateCheck;
