'use client';

import { ThemeProvider } from '@mui/material/styles';
import { theme } from 'ui';

import React from 'react';

const AppThemeProvider = ({ children }: { children: React.ReactNode }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default AppThemeProvider;
